<template>
  <div class="hooper-slider-section single-slider" v-bind:class="[slideTypeClass, titleBGColor]">
    <div v-if="title" class="hooper-slider-section-title">
      <div class="hooper-single-slider-title-text">
        <h2 v-bind:class="titleClass">{{ title }}</h2>
        <p><a v-if="titleHref" :href="titleHref" target="_blank">{{ subtitle }}</a><span v-else>{{ subtitle }}</span></p>
      </div>
    </div>
    <div class="hooper-single-slider-wrapper">
      <hooper :settings="hooperSettings">
        <slide v-for="(story, idx) in stories" :key="idx">
          <div class="hooper-slide-inner" :data-stories-index="idx">

            <PostQuoteSection v-if="story.slideType === 'quote'"
                              v-bind="story"
            />

            <template v-else>
            <div v-if="story.html" v-html="story.html" class="single-slide-media slide-custom"/>
            <template v-else>
              <div class="single-slide-media" :class="{ 'single-slide-media__has-title' : story.title }">
              <Embed v-if="story.thumbnail" :embed="story.body" :thumbnail="story.thumbnail" />
              <template v-else>
                <a :href="story.link" v-if="story.link" class="single-slide-media-body" target="_blank">
                  <div v-html="story.body"></div>
                </a>
                <div v-else class="single-slide-media-body">
                  <div v-html="story.body" @click="bodyClicked(story)"></div>
                </div>
              </template>
              </div>
              <div v-if="story.title" v-bind:class="story.slideColor" class="single-slide-info">
                <a :href="story.link" v-if="story.link" class="single-slide-info__title-link" target="_blank">
                  <h4 class="single-slide-info__title"><span v-html="story.title" /></h4>
                  <p class="single-slide-info__subtitle">{{ story.subtitle }}</p>
                </a>
                <template v-else>
                <h4 class="single-slide-info__title"><span v-html="story.title" /></h4>
                <p class="single-slide-info__subtitle">{{ story.subtitle }}</p>
                </template>
              </div>
            </template>
            </template>

          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import Embed from './Embed'
import PostQuoteSection from '@dharmachakra/tbco-shared-styles/src/components/PostQuoteSection'
import 'hooper/dist/hooper.css'
import '@dharmachakra/tbco-shared-styles/src/assets/scss/themes/_project.scss'

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    Embed,
    PostQuoteSection
  },
  props: {
    stories: Array,
    title: String,
    subtitle: String,
    titleHref: String,
    titleClass: {
      type: String,
      default: 'title'
    },
    slideColor: String,
    titleBGColor: {
      type: String,
      default: 'white'
    },
    slideTypeClass: {
      type: String,
      default: 'slider-type__hero'
    }
  },
  data () {
    return {
      hooperSettings: {
        itemsToShow: 1,
        itemsToSlide: 1,
        infiniteScroll: true,
        transition: 700,
        wheelControl: false,
        centerMode: false
      }
    }
  },
  methods: {
    bodyClicked(story) {
      this.$emit('body-clicked', story)
    }
  }
}
</script>

<style scoped lang="scss">
.white {
  background: white;
}

.grey {
  background: rgba($color: #787878, $alpha: 0.1);
}

a, a:link, a:visited, a:focus, a:hover, a:active{
  color:black;
  cursor:pointer;
}

a.story-link {
  text-decoration: none;
}
</style>
<style lang="scss">

@import './../../../www/sites/all/themes/tbc/scss/sliders.scss';
@import './../../../www/sites/all/themes/tbc/scss/_variables.scss';

.single-slide-info__subtitle {
  font-weight: 400;
  color: #fff;
  letter-spacing: 1.41px;
}

.single-slide-media-body {
  height: 100%;
  width: 100%;

  & * {
    height: 100%;
  }
}

::v-deep .single-slide-media-body {
  iframe {
    height: 100%;
    width: 100%;
  }
}

.hooper-slider-section.single-slider {
  .hooper-slider-section-title {
    padding: 0 10px;
    font-size: 15px;
    h2 {
      font-size: 24px;
      width: 100%;
      display: inline-block;
      line-height: 20px;
    }
    p {
      font-size: 15px;
      line-height: 20px;
      a {
          font-weight: 600;
      }
    }
    a {
        font-weight: 700;
    }
  }
  overflow: unset;
  padding: 0;
  .hooper-slide-inner {
    padding: 0;
    .single-slide-media {
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        min-height: 285px;
        object-fit: cover;
        display: block;
      }
    }
    .single-slide-info {
      background: #007BBD;
      width: 100%;
      max-height: 576px;
      @media all and (max-width: 480px) { height: calc(14vw + 25.1px); }
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      color: black;
      letter-spacing: 0.56px;
      line-height: 20px;
      padding: 9px 9px;
      font-weight: 600;
      overflow: hidden;
      &__title-link {
        color: white;
        text-decoration: none;
      }
      &.project-orange {
        background: #e4611d;
      }
      &.group-green {
        background: #468149;
      }
      &.course-blue {
        background: #007BBD;
      }
      &.green {
        background: #598000;
      }
      &.blue {
        background: #077897;
      }
      &.order-blue {
        background: #006AA3;
      }
      &.keystone {
        background: #007BBD;
      }
      &.purple {
        background: #8C5CA5;
      }
      &.warm-grey {
        background: #979797;
      }
      &.red {
        background: #D53424;
      }
      &.headline-grey {
        background: #757575;
      }
      h4 {
        font-size: 18px;
        letter-spacing: 0.56px;
        line-height: 20px;
        color: white;
        font-weight: 700;
        margin-top: 0em;
        margin-bottom: 0em;

        @media all and (max-width: 768px) {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        a {
          color: white;
          text-decoration: none;
        }
      }
      p {
        margin-top: 0em;
        margin-bottom: 0em;
      }
    }
  }
  .hooper-navigation {
    display: none;
    .hooper-prev {
      left: 20px;
      top: 50%;
      padding: 0;
    }
    .hooper-next {
      right: 20px;
      top: 50%;
      padding: 0;
    }
  }
  .hooper-indicators {
    position: relative;
    top: 2em;
    li {
      button {
        background-color: #909090;
        height: 10px;
        width: 10px;
        margin: 0 6px;
        border-radius: 50%;
        &.is-active {
          background-color: black;
        }
      }
    }
  }
}
@media all and (min-width: 768px) {
.hooper-slider-section.single-slider {
  padding-bottom: 5em;
  padding-top: 1px;
  .hooper-slider-section-title {
    padding: 0 20px;
    font-size: 18px;
    h2 {
      font-size: 40px;
      width: 100%;
      display: inline-block;
      line-height: 35px;
    }
    p {
      font-size: 18px;
    }
  }
  .hooper-slide-inner {
    display: flex;
    flex-direction: row;
    .single-slide-media {
      width: 100%;
    }
    .single-slide-media.single-slide-media__has-title {
      width: 71%;
    }
    .single-slide-info {
      padding: 0 9px;
      max-height: 315px;
      width: 29%;
      font-size: 28px;
      letter-spacing: 0.88px;
      line-height: 32px;
      h4 {
        font-size: 28px;
        letter-spacing: 0.88px;
        line-height: 32px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      p {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }
  }
  .hooper-indicators {
    position: relative;
    top: 3em;
  }
  &.slider-type__hero {
    .hooper-slide-inner {
      .single-slide-media, .single-slide-info {
        max-height: 315px;
      }
    }
  }
}
}
@media all and (min-width: 1000px) {
  .hooper-slider-section.single-slider {
    .hooper-slider-section-title {
      h2 {
        width: auto;
        vertical-align: baseline;
      }
      p {
        top: -2px;
        vertical-align: baseline;
      }
    }
    .hooper-slide-inner {
      .single-slide-info {
        padding: 0 74px 0 24px;
      }
    }
    .hooper-navigation {
      display: block;
    }
    &.slider-type__hero {
      .hooper-slide-inner {
        .single-slide-media, .single-slide-info {
          max-height: 576px;
        }
      }
    }
  }
}

@media all and (min-width: 1440px) {
  .hooper-slider-section.single-slider {
    .hooper-slider-section-title {
      padding: 0 20px;
      font-size: 20px;
      h2 {
        font-size: 60px;
        width: auto;
        display: inline-block;
      }
      p {
        font-size: 20px;
      }
    }
    .hooper-slide-inner {
      .single-slide-media, .single-slide-info {
        max-height: 576px;
      }
      .single-slide-info {
        padding: 0 74px 0 24px;
        font-size: 45px;
        letter-spacing: 1.4px;
        line-height: 55px;
        h4 {
          font-size: 45px;
          letter-spacing: 1.4px;
          line-height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .hooper-slider-section.single-slider.slider-type__quotes .hooper-slide-inner .single-slide-media {
    max-height: unset;
  }
}

.single-slider {
  .c-post-body-section__quote-body, .c-post-body-section__quote-author {
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 24.5px;
    @media screen and (min-width: $smartphone-width) {
      font-size: 16px;
    }
    @media screen and (min-width: 768px) {
      font-size: 28px;
      letter-spacing: 0.88px;
      line-height: 40px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 40px;
      letter-spacing: 1.25px;
      line-height: 55px;
    }
  }
}

.keystones-quotes-slider-wrapper {
  max-width: 720px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

.hooper-slider-section.single-slider.slider-type__quotes {
  .c-post-quote__custom_html_slide {
    display: flex;
    flex-direction: column;
    background-color: rgb(242,242,242);
    @media screen and (min-width: $mobile-max-size) {
      flex-direction: row;
    }
    .c-post-body-section__quote-image {
      width: 38%;
      height: 576px;
      @media screen and (max-width: $mobile-max-size) {
        margin: 0 auto;
        width: 320px;
        height: 438px;
      }
      @media screen and (min-width: $mobile-max-size) {
        display: block;
      }
      img {
        align-self: center;
        @media screen and (min-width: 1440px) {
          height: 576px;
        }
      }
    }
    .c-post-quote-wrapper {
      width: 100%;
      @media screen and (min-width: $mobile-max-size) {
        width: 62%;
        height: 564px;
      }
      @media screen and (min-width: 1440px) {
        height: 576px;
        width: 71%;
      }
      background-color: #F2F2F2;
      .c-post-body-section__quote-body {
        height: 100%;
        padding-right: 0;
        padding-left: 48px;
        &:before {
          left: 10px;
          font-size: 60px;
        }
        @media screen and (min-width: 768px) {
          padding-left: 66px;
          &:before {
            font-size: 90px;
          }
        }
        @media screen and (min-width: 1440px) {
          padding-left: 126px;
          &:before {
            left: 15px;
            font-size: 125px;
          }
        }
        .post-content {
          padding-right: 10px;
          @media screen and (min-width: 1440px) {
            padding-right: 94px;
          }
        }
      }
      .c-post-body-section__quote-author {
        width: 100%;
        padding: 0 3rem 2rem 0;
        &:after {
          font-size: 60px;
        }
        @media screen and (min-width: 768px) {
          &:after {
            font-size: 90px;
          }
        }
        @media screen and (min-width: 1440px) {
          padding: 0 6rem 2rem 0;
          &:after {
            right: 15px;
            font-size: 125px;
          }
        }
        &:after {
          right: 10px;
        }
      }
    }
  }
}
</style>
