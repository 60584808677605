<template>
  <div>
    <SingleSlider
      v-if="loaded"
      :stories="stories"
      slideTypeClass="keystones-quotes-slider-wrapper slider-type__quotes"
    />
    <p v-else>loading...</p>
  </div>
</template>

<script>
import SingleSlider from './SingleSlider.vue'
import sliderApi, { buildSlideBody } from '../api/sliders-api.js'

export default {
  components: {
    SingleSlider
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      loaded: false,
      slider: {},
      stories: []
    }
  },
  created() {
    this.fetchStories()
  },
  methods: {
    fetchStories() {
      sliderApi.fetch(this.id)
        .then(data => {
          this.slider = data.slider
          this.stories = buildSlideBody(data.slider.json, data.slider.random_order).map(slide => {
            slide.theme = 'keystone'
            return slide
          })
          this.loaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
